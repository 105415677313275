/*

A simple controller to call the specified URL and replace the content
of the element with the response.

Required structure:

<div data-controller="content-loader" data-content-loader-url="/messages">
  Loading...
</div>
*/

import { Controller } from "@hotwired/stimulus";

$ = window.$

export default class ContentLoadController extends Controller {
  connect() {
    this.load();
  }

  load() {
    fetch(this.data.get("url"))
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }
}
