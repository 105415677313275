/*

Used to hide sections of a form based on the value of a radio button (e.g. a bunch of inputs below each radio button).

Required structure:

<div data-controller="radio-hide">
  <div>
    <input type="radio" name="radio" value="1" data-action="change->radio-hide#toggle">
    <div>Stuff to be hidden...</div>
  </div>
  <div>
    <input type="radio" name="radio" value="2" data-action="change->radio-hide#toggle">
    <div>Stuff to be hidden...</div>
  </div>
</div>

*/

import { Controller } from "@hotwired/stimulus";

export default class RadioHideController extends Controller {
  static targets = ["content"];

  connect() {
    this.toggle(null);
  }

  toggle(e) {
    this.element.children.forEach((child) => {
      const otherDiv = Array.from(child.children).find((div) => !div.querySelector("input[type=radio]"));
      var radio = child.querySelector("input[type=radio]")

      if (otherDiv !== undefined) {
        if (radio.checked) {
          otherDiv.classList.remove("hidden")
        } else {
          otherDiv.classList.add("hidden")
        }
      }
    })
  }
}
