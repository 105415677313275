import { Controller } from "@hotwired/stimulus";

export default class ShowCsvDetailsController extends Controller {
  handleChange(event) {
    const selectedValue = event.target.closest('[data-value]').dataset.value;
    const normalCsvContent = document.getElementById('normal-csv-content');
    const seedLegalCsvContent = document.getElementById('SeedLegals-csv-content');
    if(selectedValue == "FounderCatalyst"){
      normalCsvContent.classList.remove('hidden');
      normalCsvContent.classList.add('block');
      seedLegalCsvContent.classList.add('hidden');
      seedLegalCsvContent.classList.remove('block');
    }
    else if(selectedValue == "SeedLegals") {
      seedLegalCsvContent.classList.remove('hidden');
      seedLegalCsvContent.classList.add('block');
      normalCsvContent.classList.add('hidden');
      normalCsvContent.classList.remove('block');
    }
  }
}
