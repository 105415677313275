import Rails from '@rails/ujs';
Rails.start();

import $ from 'jquery'
window.jQuery = $
window.$ = $

window.jQuery.each( [ "put", "delete" ], function( i, method ) {
  window.jQuery[ method ] = function( url, data, callback, type ) {
    if ( window.jQuery.isFunction( data ) ) {
      type = type || callback;
      callback = data;
      data = undefined;
    }

    return window.jQuery.ajax({
      url: url,
      type: method,
      dataType: type,
      data: data,
      success: callback
    });
  };
});

import "alpinejs"

import "./libraries/inline-attachment/input.js";

import "chartkick/chart.js"

import "./libraries/admin-search.js";

import "./controllers"
