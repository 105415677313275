/*

This controller implements very specific business logic for a funding round form.

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

// This really isn't using stimulus, but there are lots of different small actions needed in this form and
// it's easier to keep the in a single place, even if it's abusing the stimulus pattern
export default class FundingRoundController extends Controller {
  connect() {
    $("input[name=funding_round_employee_share_options_plan]").on("change", this.changeESOP)
    this.changeESOP({ target: $("input[name=funding_round_employee_share_options_plan]")[0] })
    this.changeESOP({ target: $("input[name=funding_round_employee_share_options_plan]")[1] })

    $("#company_board_investor_director_true").on("change", this.changeInvestorDirector)
    $("#company_board_investor_director_false").on("change", this.changeInvestorDirector)
    $("input[name=company_board_observer], input[name=company_board_investor_director]").on("change", this.changeInvestorDirector)
    this.changeInvestorDirector()

    $("#company_board_observer_true").on("change", this.changeBoardObserver)
    $("#company_board_observer_false").on("change", this.changeBoardObserver)
    $("input[name=company_board_observer], input[name=company_board_observer]").on("change", this.changeBoardObserver)
    this.changeBoardObserver()

    $("#funding_round_pre_money_valuation, #funding_round_additional_new_shares").on("change", this.changeValuation)
    var $nsp = $("#funding_round_additional_new_share_price")
    if ($nsp.val() == "" || parseFloat($nsp.val()) == 0.0) {
      this.changeValuation(null, false)
    }
  }

  changeESOP(e) {
    if ($(e.target).is(":checked")) {
      if ($(e.target).val() == "true") {
        $(".js-funding-round-esop-target").removeClass("hidden")
      } else {
        $(".js-funding-round-esop-target").addClass("hidden")
      }
    }
  }

  changeInvestorDirector(e) {
    if ($("#company_board_investor_director_true").is(":checked")) {
      $(".js-investor-director-target").removeClass("hidden")
    }

    if ($("#company_board_investor_director_false").is(":checked")) {
      $(".js-investor-director-target").addClass("hidden")
    }

    if ($("#company_board_observer_true:checked").val() || $("#company_board_investor_director_true:checked").val()) {
      $(".js-investor-director-expenses-target").removeClass("hidden")
    } else {
      $(".js-investor-director-expenses-target").addClass("hidden")
    }
  }

  changeBoardObserver(e) {
    if ($("#company_board_observer_true").is(":checked")) {
      $(".js-board-observer-target").removeClass("hidden")
    }

    if ($("#company_board_observer_false").is(":checked")) {
      $(".js-board-observer-target").addClass("hidden")
    }

    if ($("#company_board_observer_true:checked").val() || $("#company_board_investor_director_true:checked").val()) {
      $(".js-investor-director-expenses-target").removeClass("hidden")
    } else {
      $(".js-investor-director-expenses-target").addClass("hidden")
    }
  }

  changeValuation(e, focus) {
    var pmv = parseInt($("#funding_round_pre_money_valuation").val())
    var newShares = parseInt($("#funding_round_additional_new_shares").val())

    if (newShares > 0) {
      var $nsp = $("#funding_round_additional_new_share_price")
      var currentShares = parseInt($nsp.data("existing"))
      if (currentShares == 0) {
        currentShares = 1 // So no "Divide by zero" error
      }

      var nsp = pmv / currentShares
      if (nsp === undefined || isNaN(nsp)) {
        nsp = 1
      }
      $nsp.val(nsp)
      $(".js-funding-round-new-shares-target").removeClass("hidden")
      if (focus) {
        $("#funding_round_additional_new_share_price").focus()
      }
    } else {
      $(".js-funding-round-new-shares-target").addClass("hidden")
    }
  }
}
