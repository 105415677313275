/*

Allow the user to expand a section of content by clicking a button. The button will be hidden and the "height-class" will be removed from the content element.

Required structure:

<div data-controller="button-expander">
  <div class="max-h-[42rem] overflow-hidden" data-button-expander-target="content" data-height-class="max-h-[42rem]">
    ...
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class ButtonExpanderController extends Controller {
  static targets = ["content", "button", "overlay"];

  show(e) {
    $(this.contentTargets).removeClass("hidden")
    $(e.target).addClass("hidden")
    e.preventDefault()
    return false
  }

  removeOverlay(e) {
    $(this.overlayTargets).addClass("hidden")
    $(this.contentTarget).removeClass($(this.contentTarget).data("heightClass"))
  }
}
