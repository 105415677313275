/*

This controller is used to toggle between light, dark, and auto themes.

It stores the choice in localStorage so that it persists between page loads.

*/

import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const storedMode = window.localStorage.getItem("darkmode")
    if (storedMode) {
      $(`#theme-toggle input[value='${storedMode}']`).checked = true
      this.setState(storedMode)
    } else {
      this.setState("auto")
    }
  }

  setState(state) {
    document.body.classList.remove("light", "dark", "auto")
    document.body.classList.add(state)
    window.localStorage.setItem("darkmode", state)
  }

  light(e) {
    this.setState("light")
  }

  auto(e) {
    this.setState("auto")
  }

  dark(e) {
    this.setState("dark")
  }
}
