import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class SupportController extends Controller {
  static targets = ["body", "dropdown"];

  connect() {
    if (window.location.hash != "") {
      var hash = window.location.hash.substring(1);
      for (const key in window.supportReasons) {
        var reason = window.supportReasons[key]
        if (reason.shortcode == hash) {
          $(this.dropdownTarget).val(key);
          this.reasonChange({ target: this.dropdownTarget });
          break
        }
      }
    }
  }

  reasonChange(e) {
    const token = "{{CURSOR_POSITION}}";

    var select = $(e.target)
    var reason = select.val()
    var newBody = ""
    if (window.supportReasons[reason] != undefined) {
      if (window.supportReasons[reason].body == undefined) {
        var templateBody = window.supportReasons[reason]
      } else {
        var templateBody = window.supportReasons[reason].body
      }
      newBody = templateBody.replace(/\n/g, '\n');
    }

    this.bodyTarget.focus();
    this.bodyTarget.value = newBody;

    // Find the start and end index of the token
    const tokenStartIndex = this.bodyTarget.value.indexOf(token);
    const tokenEndIndex = tokenStartIndex + token.length;

    if (tokenStartIndex === -1) {
      return
    }

    // Replace the token with an empty string
    const newValue = this.bodyTarget.value.slice(0, tokenStartIndex) + this.bodyTarget.value.slice(tokenEndIndex);

    // Set the new value and update the cursor position
    this.bodyTarget.value = newValue;
    this.bodyTarget.selectionStart = tokenStartIndex;
    this.bodyTarget.selectionEnd = tokenStartIndex;
  }
}
