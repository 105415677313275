/*

A simple calculator for our free "Equity Calculator" tool.

Required structure:

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class CalculatorController extends Controller {
  static targets = ["amount", "equity", "premoney", "postmoney"]

  lastTwoFields = []

  updateValuationFromAmount(event) {
    this.setToLastTwoFields(this.amountTarget, event)
    let amount = this.removeCurrency(this.amountTarget.value)

    if (this.equityTarget.value != "" && this.lastTwoFields.includes(this.equityTarget)) {
      let equity = this.removeCurrency(this.equityTarget.value)
      let postmoney = amount / equity * 100
      let premoney = postmoney - amount
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.premoneyTarget.value != "" && this.lastTwoFields.includes(this.premoneyTarget)) {
      let premoney = this.removeCurrency(this.premoneyTarget.value)
      let postmoney = premoney + amount
      let equity = amount / premoney * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.postmoneyTarget.value != "" && this.lastTwoFields.includes(this.postmoneyTarget)) {
      let postmoney = this.removeCurrency(this.postmoneyTarget.value)
      let premoney = postmoney - amount
      let equity = amount / postmoney * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
    }
  }

  updateValuationFromEquity(event) {
    this.setToLastTwoFields(this.equityTarget, event)
    let equity = this.removeCurrency(this.equityTarget.value)

    if (this.amountTarget.value != "" && this.lastTwoFields.includes(this.amountTarget)) {
      let amount = this.removeCurrency(this.amountTarget.value)
      let postmoney = amount / equity * 100
      let premoney = postmoney - amount
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.premoneyTarget.value != "" && this.lastTwoFields.includes(this.premoneyTarget)) {
      let premoney = this.removeCurrency(this.premoneyTarget.value)
      let amount = ((equity / 100) * premoney) / ((100 - equity) / 100)
      let postmoney = premoney + amount
      this.setValue(this.amountTarget, amount, this.formatCurrency(amount))
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.postmoneyTarget.value != "" && this.lastTwoFields.includes(this.postmoneyTarget)) {
      let postmoney = this.removeCurrency(this.postmoneyTarget.value)
      let amount = postmoney * (equity / 100)
      let premoney = postmoney - amount
      this.setValue(this.amountTarget, amount, this.formatCurrency(amount))
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
    }
  }

  updateValuationFromPreMoney(event) {
    this.setToLastTwoFields(this.premoneyTarget, event)
    let premoney = this.removeCurrency(this.premoneyTarget.value)

    if (this.equityTarget.value != "" && this.lastTwoFields.includes(this.equityTarget)) {
      let equity = this.removeCurrency(this.equityTarget.value)
      let amount = ((equity / 100) * premoney) / ((100 - equity) / 100)
      let postmoney = premoney + amount
      this.setValue(this.amountTarget, amount, this.formatCurrency(amount))
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.amountTarget.value != "" && this.lastTwoFields.includes(this.amountTarget)) {
      let amount = this.removeCurrency(this.amountTarget.value)
      let postmoney = premoney + amount
      let equity = amount / postmoney * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.postmoneyTarget, postmoney, this.formatCurrency(postmoney))
    } else if (this.postmoneyTarget.value != "" && this.lastTwoFields.includes(this.postmoneyTarget)) {
      let postmoney = this.removeCurrency(this.postmoneyTarget.value)
      let amount = postmoney - premoney
      let equity = amount / premoney * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
    }
  }

  updateValuationFromPostMoney(event) {
    this.setToLastTwoFields(this.postmoneyTarget, event)
    let postmoney = this.removeCurrency(this.postmoneyTarget.value)

    if (this.equityTarget.value != "" && this.lastTwoFields.includes(this.equityTarget)) {
      let equity = this.removeCurrency(this.equityTarget.value)
      let amount = (postmoney / 100) * equity
      let premoney = postmoney - amount
      this.setValue(this.amountTarget, amount, this.formatCurrency(amount))
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
    } else if (this.amountTarget.value != "" && this.lastTwoFields.includes(this.amountTarget)) {
      let amount = this.removeCurrency(this.amountTarget.value)
      let premoney = postmoney - amount
      let equity = (amount / postmoney) * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.premoneyTarget, premoney, this.formatCurrency(premoney))
    } else if (this.premoneyTarget.value != "" && this.lastTwoFields.includes(this.premoneyTarget)) {
      let premoney = this.removeCurrency(this.premoneyTarget.value)
      let amount = postmoney - premoney
      let equity = amount / postmoney * 100
      this.setValue(this.equityTarget, equity, equity)
      this.setValue(this.amountTarget, amount, this.formatCurrency(amount))
    }
  }

  setValue(target, value, formattedValue) {
    if (!isNaN(value) && value > 0 && value != Infinity) {
      target.value = formattedValue
    }
  }

  formatCurrency(number) {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'GBP' }).replace("£", "")
  }

  removeCurrency(orig) {
    let value = ""
    if (orig != undefined) {
      value = parseFloat(orig.replaceAll(",", ""))
    }
    if (value == undefined || isNaN(value)) {
      value = ""
    }
    return value
  }

  removeCurrencyFormatting(e) {
    e.target.value = this.removeCurrency(e.target.value)
  }

  applyCurrencyFormatting(e) {
    if (e.target.value != "") {
      e.target.value = this.formatCurrency(parseFloat(e.target.value))
    }
  }

  setToLastTwoFields(target, event) {
    const validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace", "Delete"]
    if (!validKeys.includes(event.key)) {
      return
    }

    if (!this.lastTwoFields.includes(target) && target.value != "" && target.value != undefined && !isNaN(target.value)) {
      this.lastTwoFields.unshift(target)
      if (this.lastTwoFields.length > 2) {
        this.lastTwoFields.pop()
      }
    }
  }
}
