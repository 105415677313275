/*

ASA controller

Simple inputs for creating an ASA and asking for a value to convert to.

*/

import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class ASAController extends Controller {
  static targets = [];

  convert(e) {
    var $this = $(e.target).closest("button")
    var id = $this.data("id")
    var defaultShares = $this.data("shares")
    // TODO: Replace with a nice modal?
    var sharesText = prompt("How many shares should the investment convert to?", defaultShares)
    var shares = parseInt(sharesText)

    if (Number.isNaN(shares)) {
      return
    }

    $.ajax({
      type: "PUT",
      url: `/account/asa/${id}/convert`,
      data: { shares: shares },
      dataType: "json",
      success: function (data) {
        if (data.success) {
          location.reload()
        } else {
          // TODO: Replace with a nice error some time?
          alert(data.error)
        }
      }
    })
  }
}
