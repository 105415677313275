/*

HOW TO USE:

Via a click, loading via Ajax:

<button data-controller="modal" data-action="click->modal#show" data-url="/messages" />

When the page loads, from local existing content:

<div class="hidden" data-controller="modal" data-action="load@window->modal#show">
  <div data-modal-target="content">
    ...
  </div>
</div>

*/

import { Controller } from "@hotwired/stimulus";

$ = window.$;

export default class ModalController extends Controller {
  static targets = ["content"];

  connect() { }

  show(e) {
    if ($("#modal").length == 0) {
      $("body").append("<div id='modal'></div>");
    } else {
      $("#modal").html("");
    }

    if (this.hasContentTarget) {
      var html = `
        <div class="fixed z-10 inset-0 overflow-y-auto select-none" data-controller="modal">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-black/20 backdrop-blur-sm" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      `;
      html = html + $(this.contentTarget).html();
      html =
        html +
        `
            </div>
          </div>
        </div>
      `;
      $("#modal").append(html);
    } else {
      var $wrapper = $(e.target).closest('[data-controller="modal"]');
      var url = $wrapper.data("url");

      var controller = this;
      $("#modal").load(url, function (responseText, textStatus, jqXHR) {
        controller.connectRemoteForms(controller);
      });
    }

    e.preventDefault();
    return false;
  }

  close() {
    $("#modal").remove();
  }

  connectRemoteForms(controller) {
    $("#modal form[data-modal-remote=true]").on("submit", function (e) {
      e.preventDefault();
      var $form = $(this)

      var formData = new FormData(this);
      if ($form.find("input[type=file]")[0] != undefined) {
        var files = $form.find("input[type=file]")[0].files;
        $.each(files, function () {
          var file = $(this);
          formData.append(file[0].name, file[0]);
        });
      }

      $.ajax({
        url: $(this).attr("action"),
        type: $(this).attr("method"),
        data: formData,
        processData: false,  // Add this line
        contentType: false,  // And this line
        success: function (data, status, xhr) {
          if (data.location == undefined) {
            $("#modal").html(data);
            controller.connectRemoteForms(controller);
          } else {
            window.location.href = data.location
            window.location.reload()
            $("#modal").remove()
          }
        },
      });
    });
  }
}
