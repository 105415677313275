/*

A simple controller to embed a PDF viewer onto the page.

Required structure:

<div data-controller="pdf-viewer">
  <div data-pdf-viewer-target="embed" data-pdf-viewer-src="/foo.pdf"></div>
    <div data-pdf-viewer-target="fallback">
      Unable to embed - <a href="/foo.pdf">Download PDF</a>
    </div>
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

import PDFObject from 'pdfobject';

export default class PDFViewerController extends Controller {
  static targets = ["embed", "fallback"];

  connect() {
    if (PDFObject.supportsPDFs) {
      PDFObject.embed(this.embedTarget.dataset.pdfViewerSrc, this.embedTarget);
      this.embedTarget.classList.remove("hidden");
      this.fallbackTarget.classList.add("hidden");
    } else {
      this.embedTarget.classList.add("hidden");
      this.fallbackTarget.classList.remove("hidden");
    }
  }
}
