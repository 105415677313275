/*

This controller implements drag and drop image uploading
in to textarea contents for Markdown insertion.

Required structure:

<textarea data-controller="inline-image" />

*/

import { Controller } from "@hotwired/stimulus"

$ = window.$

import InputInlineAttachment from '../libraries/inline-attachment/input';

export default class InlineImageController extends Controller {
  connect() {
    var v = new InputInlineAttachment(this.element, {
      uploadUrl: "/admin/image_uploads",
      urlText: "![TODO - Uploaded image description]({filename})"
    });
  }
}
