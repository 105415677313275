/*

This controller is responsible for toggling the mobile menu.

TODO: It could be very generic, but it's currently written quite specifically for that use case.

*/

import { Controller } from "@hotwired/stimulus"

export default class MobileMenuController extends Controller {
  static targets = ["menu", "content"];

  toggle(event) {
    this.menuTarget.classList.toggle("-translate-x-full")
    // this.contentTarget.classList.toggle("hidden")
  }
}
