/*

Character limits warning

Given a form input or textarea with "data-character-limits-target=output" and "data-limit" set to an integer, this controller will add a character limit warning to the input or textarea's parent element.

*/

import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class CharacterLimitsController extends Controller {
  static targets = ["output"]

  connect() {
    var $elem = $(this.element)
    $elem.append("<div class=\"mt-1 text-right text-sm js-character-limits-label\"></div>")
    this.update({ target: $elem.find("input, textarea") })
  }

  update(e) {
    var $elem = $(e.target)
    var count = $elem.val().length
    var limit = parseInt($elem.data("limit"))
    var $label = $elem.siblings(".js-character-limits-label")
    $label.text(count.toString() + " / " + limit.toString() + " characters used (" + (limit - count).toString() + " left)")
    if (count > limit) {
      $label.addClass("text-red-700 font-semibold").removeClass("text-orange-400 text-gray-500")
    } else if (count > (limit * 0.8)) {
      $label.addClass("text-orange-400 font-semibold").removeClass("text-red-700 text-gray-500")
    } else {
      $label.addClass("text-gray-500").removeClass("text-red-700 text-orange-400 font-semibold")
    }
  }
}
