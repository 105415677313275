import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class StickyMenuController extends Controller {
  static targets = ["link"]

  connect() {
  }

  click(e) {
    $(this.linkTargets).removeClass("bg-highlight-500 text-white").addClass("bg-highlight-50 text-brand-500");
    $(e.target).removeClass("bg-highlight-50 text-brand-500").addClass("bg-highlight-500 text-white");
    $(e.target).closest("div").addClass("hidden");
  }
}
