/*

Image-based PDF Viewer Component

Required structure:

<div class="mt-8" id="pdf-image-viewer-container">
  <div
   data-controller="pdf-image-viewer"
   data-pdf-image-viewer-pages="10"
   data-pdf-image-viewer-first-page-width="250"
   data-pdf-image-viewer-first-page-height="500"
   data-pdf-image-viewer-src="/document/1234/pages"
  >
    <div class="flex">
      <div class="pdf-image-viewer-previous-page"></div>
      <div class="pdf-image-viewer-pages"></div>
      <div class="pdf-image-viewer-next-page"></div>
    </div>
    <div class="pdf-image-viewer-toolbar">
      <div class="pdf-image-viewer-page-number"></div>
      <div class="pdf-image-viewer-total-pages"></div>
      <div class="pdf-image-viewer-full-screen"></div>
    </div>
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class PDFViewerController extends Controller {
  connect() {
    var $this = $(this.element)
    if ($this.data("currentPage") === undefined) {
      this.createContainer();
      this.connectActionButtons()
      this.displayCurrentPages()
    }
  }

  createContainer() {
    var $this = $(this.element)

    var width = $this.data("pdfImageViewerFirstPageWidth")
    var height = $this.data("pdfImageViewerFirstPageHeight")

    var $grid = $this.find(".pdf-image-viewer-pages")
    $grid.addClass("grid bg-gray-200 p-4")
    $this.data("$grid", $grid)

    $grid.append("<div class='bg-white'><img class='h-auto'></div>")
    if (height > width) {
      $grid.append("<div class='bg-white'><img class='h-auto'></div>")
      $grid.addClass("grid-cols-2")
    }

    $this.data("currentPage", 0)
  }

  connectActionButtons() {
    var $this = $(this.element)
    $this.find(".pdf-image-viewer-previous-page").on("click", () => { this.previousPage(this) })
    $this.find(".pdf-image-viewer-next-page").on("click", () => { this.nextPage(this) })
  }

  nextPage() {
    var $this = $(this.element)
    var $grid = $this.data("$grid")

    var pagesToDisplay = $grid.find("img").length
    var currentPage = $this.data("currentPage")
    if (currentPage >= $this.data("pdfImageViewerPages") - pagesToDisplay) {
      return
    }
    $this.data("currentPage", currentPage + pagesToDisplay)
    this.displayCurrentPages()
  }

  previousPage() {
    var $this = $(this.element)
    var $grid = $this.data("$grid")

    var pagesToDisplay = $grid.find("img").length
    var currentPage = $this.data("currentPage")
    currentPage = currentPage - pagesToDisplay
    if (currentPage < 0) {
      currentPage = 0
    }
    $this.data("currentPage", currentPage)
    this.displayCurrentPages()
  }

  displayCurrentPages() {
    var $this = $(this.element)
    var $grid = $this.data("$grid")

    $grid.find("img").first().attr("src", $this.data("pdfImageViewerSrc") + "?page=" + $this.data("currentPage"))
    if ($grid.find("img").length == 2) {
      if ($this.data("currentPage") + 1 == $this.data("pdfImageViewerPages")) {
        $grid.find("img").last().addClass("hidden")
      } else {
        $grid.find("img").last().removeClass("hidden").attr("src", $this.data("pdfImageViewerSrc") + "?page=" + ($this.data("currentPage") + 1))
      }
    }

    $this.find(".pdf-image-viewer-page-number").text($this.data("currentPage") + 1)
    $this.find(".pdf-image-viewer-total-pages").text($this.data("pdfImageViewerPages"))
  }

  toggleFullScreen() {
    var $this = $(this.element)

    if ($this.hasClass("full-screen")) {
      $this.removeClass("full-screen absolute z-10 inset-0 h-screen")
      $this.detach()
      $this.data("parent").append($this)
      $this.data("parent", undefined)
    } else {
      $this.addClass("full-screen absolute z-10 inset-0 h-screen")
      $this.data("parent", $this.parent())
      $this.detach()
      $("body").append($this)
    }
  }
}
