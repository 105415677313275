/*

This controller implements a interval based system for
pulling current notifications from the API and updating
the UI, including marking them as read.

<div data-controller="notifications">
  <div data-action="click->notifications#markAll">Mark as read</div>
  <div class="notifications-listing-container-items"></div>
</div>

*/

import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"

$ = window.$

export default class NotificationsController extends Controller {
  static targets = ["mark"];

  connect() {
    var controller = this
    var element = this.element
    var $container = $(element).find(".notifications-listing-container-items")

    if ($container.length > 0) {
      setInterval(function () {
        controller.getNotifications(element)
      }, 30000);
      this.getNotifications(element)
    }
  }

  markAll(e) {
    var element = this.element
    var controller = this

    $.ajax({
      type: "PUT",
      url: "/account/notifications/all-read",
      dataType: "json",
      success: function (data) {
        controller.getNotifications(element)
        if ($(this.markTarget).data("notificationsReloadAfter")) {
          location.reload()
        }
      }
    })
  }

  getNotifications(element) {
    var $element = $(element)

    var $container = $element.find(".notifications-listing-container-items")

    var presentIcon = $element.find("#notifications-present");
    var presentCount = $element.find(".notifications-count")
    var absentIcon = $element.find("#notifications-absent");
    var itemTemplate = template($("#notifications-listing-item").html());

    $.ajax({
      type: "GET",
      url: "/account/notifications",
      dataType: "json",
      success: function (data) {
        if (data.items.length < 1) {
          $container.html('<div class="text-gray-700 text-center leading-none py-4 notifications-empty">No notifications found</div>')
          presentIcon.addClass("hidden")
          absentIcon.removeClass("hidden")
        } else {
          $container.find(".notifications-empty").remove()
          if (data.unread > 0) {
            presentIcon.removeClass("hidden")
            absentIcon.addClass("hidden")
            presentCount.html(data.unread)
          } else {
            presentIcon.addClass("hidden")
            absentIcon.removeClass("hidden")
          }

          $container.find("a").data("delete", "true")

          $.each(data.items, function (key, item) {
            var $content = $(itemTemplate(item))
            if (item.unread) {
              $content.find(".notification-unread").removeClass("invisible")
            }

            $element = $container.find(`[data-id="${item.id}"]`)
            if ($element.length > 0) {
              $element.replaceWith($content)
            } else {
              $container.append($content)
            }
          })

          $container.find("a[data-delete='true']").remove()
        }
      }
    })
  }
}
