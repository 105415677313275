/*

This controller is used to replace the explainer video thumbnail with
the actual video when clicked.

Required structure:

<img data-controller="explainer" data-action="click->explainer#click" src="thumbnail.jpg" />

*/

import { Controller } from "@hotwired/stimulus";

$ = window.$

export default class ExplainerController extends Controller {
  click(e) {
    $(e.target).replaceWith("<iframe src=\"https://www.youtube.com/embed/t3wdZqUntUg?autoplay=1&cc_load_policy=1\" class=\"w-full aspect-video\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>")
  }
}
