/*

This controller implements very specific data creation for Heartbeat definitions

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class HeartbeatDefinitionController extends Controller {
  static targets = ["newButton", "addForm", "labelDropdown", "customLabelInput", "typeDropdown", "displayDropdown", "itemTemplate", "itemsContainer", "hiddenField"];

  connect() {
    this.loadExistingItems()
  }

  newItem(e) {
    this.addFormTarget.classList.remove("hidden")
    this.newButtonTarget.classList.add("hidden")
  }

  submitStandardForm(e) {
    e.preventDefault()
    e.stopPropagation()

    let label = this.labelDropdownTarget.value

    let errors = false
    if (label == "Please choose one") {
      this.labelDropdownTarget.classList.add("border-red-500")
      errors = true
    } else {
      this.labelDropdownTarget.classList.remove("border-red-500")
    }
    if (errors) {
      return
    }

    let standard = window.standardHeartbeatDataItems.find(item => item.name == label)

    let content = this.itemTemplateTarget.innerHTML
    content = content.replace(/{{LABEL}}/g, standard.name)
    content = content.replace(/{{TYPE}}/g, standard.value_type)
    content = content.replace(/{{DISPLAY}}/g, standard.display_type)
    content = content.replace(/{{ID}}/g, this.generateID())

    this.itemsContainerTarget.innerHTML += content

    this.syncItemsToHiddenField()

    this.cancelForm({})
  }

  submitCustomForm(e) {
    e.preventDefault()
    e.stopPropagation()

    let label = this.customLabelInputTarget.value

    let type = this.typeDropdownTarget.value
    let display = this.displayDropdownTarget.value

    let errors = false
    if (label == "Please choose one") {
      this.labelDropdownTarget.classList.add("border-red-500")
      errors = true
    } else {
      this.labelDropdownTarget.classList.remove("border-red-500")
    }
    if (type == "Please choose one") {
      this.typeDropdownTarget.classList.add("border-red-500")
      errors = true
    } else {
      this.typeDropdownTarget.classList.remove("border-red-500")
    }
    if (display == "Please choose one") {
      this.displayDropdownTarget.classList.add("border-red-500")
      errors = true
    } else {
      this.displayDropdownTarget.classList.remove("border-red-500")
    }
    if (errors) {
      return
    }

    let content = this.itemTemplateTarget.innerHTML
    content = content.replace(/{{LABEL}}/g, label)
    content = content.replace(/{{TYPE}}/g, type)
    content = content.replace(/{{DISPLAY}}/g, display)
    content = content.replace(/{{ID}}/g, this.generateID())

    this.itemsContainerTarget.innerHTML += content

    this.syncItemsToHiddenField()

    this.cancelForm({})
  }

  removeItem(e) {
    e.target.closest(".js-heartbeat-definition-item").remove()
    this.syncItemsToHiddenField()
  }

  cancelForm(e) {
    this.labelDropdownTarget.selectedIndex = 0
    this.typeDropdownTarget.selectedIndex = 0
    this.displayDropdownTarget.selectedIndex = 0

    this.addFormTarget.classList.add("hidden")
    this.newButtonTarget.classList.remove("hidden")
  }

  loadExistingItems() {
    let items = this.hiddenFieldTarget.value.split("|")
    items.forEach((item, index) => {
      if (item == "") {
        return
      }
      let content = this.itemTemplateTarget.innerHTML
      item.split(",").forEach((data, index) => {
        let [key, value] = data.split(":")

        if (key == "label") {
          content = content.replace(/{{LABEL}}/g, value)
        }
        if (key == "type") {
          content = content.replace(/{{TYPE}}/g, value)
        }
        if (key == "display") {
          content = content.replace(/{{DISPLAY}}/g, value)
        }
        if (key == "id") {
          content = content.replace(/{{ID}}/g, value)
        }
      })
      this.itemsContainerTarget.innerHTML += content
    })
  }

  syncItemsToHiddenField(e) {
    let items = []
    this.itemsContainerTarget.querySelectorAll(".js-heartbeat-definition-item").forEach((item, index) => {
      let data = `id:${item.dataset.id},label:${item.dataset.label},type:${item.dataset.type},display:${item.dataset.display}`
      items.push(data)
    })

    this.hiddenFieldTarget.value = items.join("|")
  }

  generateID() {
    const len = 8
    const hex = '0123456789ABCDEF'

    let output = ''
    for (let i = 0; i < len; ++i) {
      output += hex.charAt(Math.floor(Math.random() * hex.length))
    }

    return output
  }
}
