import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class ToggleController extends Controller {
  static targets = ["panel", "container"];

  connect() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    if (vw <= 768) {
      return
    }

    $(this.element).removeClass("lg:hidden").addClass("flex")
    this.data.set("current", "1")
    this.startAnimate()
  }

  disconnect() {
    this.endAnimate()
  }

  startAnimate() {
    this.endAnimate()

    if ($(this.element).data("startImmediately") == true) {
      $(this.element).data("startImmediately", false)
      this.animate()
    }

    var delay = $(this.element).data("slideDuration")
    if (delay == null) {
      delay = 5000
    }

    $(this.element).data("interval", setInterval(() => {
      this.animate()
    }, delay))
  }

  endAnimate() {
    clearInterval($(this.element).data("interval"))
  }

  back() {
    if (this.data.get("current") != "1") {
      this.data.set("current", parseInt(this.data.get("current")) - 1)
      this.displayCurrent()
    }
  }

  next() {
    if (this.data.get("current") != this.panelTargets.length.toString()) {
      this.data.set("current", parseInt(this.data.get("current")) + 1)
      this.displayCurrent()
    }
  }

  animate() {
    $(this.panelTargets).removeClass("opacity-100").addClass("opacity-0")
    if (this.data.get("current") != this.panelTargets.length.toString()) {
      this.next()
    } else {
      this.data.set("current", 1)
      this.displayCurrent()
    }
  }

  displayCurrent() {
    var panels = this.panelTargets
    var panelWidth = parseInt($(this.panelTargets[0]).css("width"))
    var current = parseInt(this.data.get("current")) - 1
    $(this.containerTarget).css("margin-left", (-(current * panelWidth)).toString() + "px")
    $(this.panelTargets[current]).addClass("opacity-100").removeClass("opacity-0")
  }
}
