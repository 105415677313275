import { Controller } from "@hotwired/stimulus";

// <form action="/upload" method="post" data-controller="upload-image">
//   <div ...>
//     <input type="file" name="file" class="hidden" />
//     <span>Drop here...</span>
//   </div>
// </form>

import Croppie from 'croppie'
import Dropzone from 'dropzone'

export default class UploadImageController extends Controller {
  static targets = ["drop"];

  connect() {
    var controller = this
    var $form = $(this.element);
    var $target = $(this.dropTargets[0])

    var AUTH_TOKEN = $('meta[name="csrf-token"]').attr("content");

    var myDropzone = new Dropzone($target[0], {
      uploadMultiple: false,
      maxFiles: 1,
      previewsContainer: false,
      acceptedFiles: 'image/jpeg,image/jpg,image/png',
      clickable: this.dropTargets[0],
      params: {
        authenticity_token: AUTH_TOKEN,
      },
      url: $form.attr("action"),
      transformFile: function (file, done) {
        controller.cropImage($target, file, done);
      },
    });

    myDropzone.on("success", function (file, response) {
      if (response.location != undefined) {
        window.location.href = response.location
      }
    });
  }

  cropImage($elem, file, done) {
    $elem.addClass("hidden");
    $elem
      .parent()
      .append(
        "<div class='js-uploader-submit mt-4 mb-10'><button class='bg-brand-500 text-white font-medium px-2 py-1 rounded hover:bg-brand-600'>Upload</button></div>"
      );
    var $editor = $elem
      .parent()
      .append(
        "<div class='w-full h-72'><div id='uploader-cropper'></div><div class='uploader-confirm my-2'></div></div>"
      );
    var $button = $editor.find(".js-uploader-submit");

    console.log($elem)
    var enableResize = false;
    if ($elem.data("resize")) {
      enableResize = true;
    }

    var croppie = new Croppie(document.getElementById('uploader-cropper'), {
      enableExif: true,
      enableResize: enableResize,
      mouseWheelZoom: false,
      viewport: {
        width: 200,
        height: 200,
        type: "square",
      },
    });

    croppie.bind({
      url: URL.createObjectURL(file),
    }).then(function(){
      croppie.setZoom(0);
    });

    $button.on("click", function (e) {
      e.preventDefault();
      croppie.result({type: "blob"}).then(function (blob) {
        $elem[0].dropzone.createThumbnail(
          blob,
          $elem[0].dropzone.options.thumbnailWidth,
          $elem[0].dropzone.options.thumbnailHeight,
          $elem[0].dropzone.options.thumbnailMethod,
          false,
          function (dataURL) {
            $elem[0].dropzone.emit("thumbnail", file, dataURL);
            done(blob);
          }
        );
      });

      $elem.remove($editor);
    });
  }
}
