import { Controller } from "@hotwired/stimulus";

$ = window.$

export default class TooltipController extends Controller {
  connect() {
    if (this.element.getAttribute("data-tooltip") === null) {
      return
    }

    this.element.removeAttribute('data-controller', 'tooltip')
    var wrapperContent = this.element.outerHTML + "<div class=\"p-2 rounded absolute bg-white text-gray-800 text-sm border-gray-100 border shadow-lg text-left hidden js-tooltip\">" + this.element.getAttribute("data-tooltip") + "</div>"

    const wrapper = document.createElement('div');
    wrapper.classList.add("js-tooltip-parent")
    wrapper.innerHTML = wrapperContent

    $(this.element).replaceWith(wrapper)

    $(wrapper).on("mouseover", this.showTooltip)
    $(wrapper).on("mouseout", this.hideTooltip)
  }

  showTooltip(event) {
    $(event.target).closest(".js-tooltip-parent").find(".js-tooltip").removeClass('hidden')
  }

  hideTooltip(event) {
    $(event.target).closest(".js-tooltip-parent").find(".js-tooltip").addClass('hidden')
  }
}
