/*

Admin quick-search interface

When the user clicks on the search box or uses the hotkey Cmd+K (powered by admin-search.js)
the search box is displayed. The user can then type in a search query and the results are
displayed. The CSS class .js-admin-search-trigger is how the two JS files are hooked together.

Required structure:

<body data-controller="admin-search">
  <div class="js-admin-search-trigger" data-admin-search-target="trigger">...</div>
  <div class="hidden fixed inset-0 place-items-center backdrop-blur" data-admin-search-target="box">
    <input type="text" data-admin-search-target="input" data-action="keyup->admin-search#keyup">

    <!-- This is the template for an individual search item -->
    <template class="js-admin-search-template">
      <a href="${url}">
        <img src="${image}">
        <span>${initials}</span>
        <span>${name}</span>
        <span>${extra}</span>
      </a>
    </template>

    <!-- Search results appear here, injecting each as the template -->
    <div class="js-admin-search-items">
      <div class="js-admin-search-people">
        <div class="items"></div>
      </div>
      <div class="js-admin-search-companies">
        <div class="items"></div>
      </div>
    </div>
  </div>
</body>

*/

import { Controller } from "@hotwired/stimulus";

import { template } from "lodash";

$ = window.$;

export default class AdminSearchController extends Controller {
  static targets = [
    "trigger",
    "input",
    "box",
    "cancel",
    "searchIcon",
    "searchingIcon",
  ];

  connect() {
    this.searchTimeout = undefined;
    var $boxTarget = $(this.boxTarget);
    var $input = $boxTarget.find("input");

    $(this.triggerTarget).on("click", function () {
      $boxTarget.addClass("grid").removeClass("hidden");
      $input[0].focus();
    });
    $(this.cancelTarget).on("click", function () {
      if (this.searchTimeout != undefined) {
        clearTimeout(this.searchTimeout);
      }

      $boxTarget.removeClass("grid").addClass("hidden");
      window.focus();
    });

    this.template = template($(".js-admin-search-template").html());
  }

  keyup(event) {
    var $boxTarget = $(this.boxTarget);
    var $input = $boxTarget.find("input");
    var $searchIconTarget = $(this.searchIconTarget);
    var $searchingIconTarget = $(this.searchingIconTarget);
    var controller = this;

    if (event.key == "Escape") {
      if (this.searchTimeout != undefined) {
        clearTimeout(this.searchTimeout);
      }
      $boxTarget.removeClass("grid").addClass("hidden");
      return false;
    } else if (event.key == "ArrowUp") {
      var $results = $(".js-admin-search-items a");
      var currentItem = parseInt($results.data("currentItem"));
      if (
        (currentItem == null ||
          isNaN(currentItem) ||
          currentItem == undefined) &&
        $results.length > 0
      ) {
        currentItem = 0;
      } else {
        currentItem = currentItem - 1;
        if (currentItem < 0) {
          currentItem = 0;
        }
      }
      $results.data("currentItem", currentItem);
      controller.selectCurrentItem();
      event.preventDefault();
      return false;
    } else if (event.key == "ArrowDown") {
      var $results = $(".js-admin-search-items a");
      var currentItem = parseInt($results.data("currentItem"));
      if (
        (currentItem == null ||
          isNaN(currentItem) ||
          currentItem == undefined) &&
        $results.length > 0
      ) {
        currentItem = 0;
      } else {
        currentItem = currentItem + 1;
        if (currentItem == $results.length) {
          currentItem = $results.length - 1;
        }
      }
      $results.data("currentItem", currentItem);
      controller.selectCurrentItem();
      event.preventDefault();
      return false;
    } else if (event.key == "Enter") {
      var $results = $(".js-admin-search-items a");
      if ($results.data("currentItem") !== undefined) {
        window.location = $(
          $results[parseInt($results.data("currentItem"))]
        ).attr("href");
      }
      event.preventDefault();
      return false;
    }
    if (this.searchTimeout != undefined) {
      clearTimeout(this.searchTimeout);
    }

    var value = $input.val();
    if (value.length >= 3) {
      $boxTarget.find(".js-admin-search-warning").addClass("hidden");
      this.searchTimeout = setTimeout(function () {
        $searchIconTarget.addClass("hidden");
        $searchingIconTarget.removeClass("hidden");
        $.get("/admin/search?q=" + value, function (data, textStatus, jqXHR) {
          if (data.users.length == 0) {
            $(".js-admin-search-people").addClass("hidden");
          } else {
            $(".js-admin-search-people").removeClass("hidden");
            $(".js-admin-search-people .items").html("");
            for (const i in data.users) {
              var person = data.users[i];
              // Take the first initial of each of the first and last word and uppercase them
              var initials = person.name.split(" ").map(function (word) {
                return word[0].toUpperCase();
              });
              var initial_string =
                initials[0] + initials[initials.length - 1];
              var $block = $(".js-admin-search-people .items").append(
                controller.template({
                  url: person.url,
                  image: person.image,
                  name: person.name,
                  extra: person.extra,
                  initials: initial_string,
                })
              );
              if (person.image == null || person.image == undefined) {
                $block.find("img").remove();
                $block.find("div.hidden").removeClass("hidden");
              }
            }
          }

          if (data.companies.length == 0) {
            $(".js-admin-search-companies").addClass("hidden");
          } else {
            $(".js-admin-search-companies").removeClass("hidden");
            $(".js-admin-search-companies .items").html("");
            for (const i in data.companies) {
              var company = data.companies[i];
              var initials = company.name.split(" ").map(function (word) {
                return word[0].toUpperCase();
              });
              var initial_string = initials[0] + initials[1];
              var $block = $(".js-admin-search-companies .items").append(
                controller.template({
                  url: company.url,
                  image: company.image,
                  name: company.name,
                  extra: company.extra,
                  initials: initial_string,
                })
              );
              if (company.image == null || company.image == undefined) {
                $block.find("img").remove();
                $block.find("div.hidden").removeClass("hidden");
              }
            }
          }

          if (data.tickets.length == 0) {
            $(".js-admin-search-tickets").addClass("hidden");
          } else {
            $(".js-admin-search-tickets").removeClass("hidden");
            $(".js-admin-search-tickets .items").html("");
            for (const i in data.tickets) {
              var ticket = data.tickets[i];
              console.log(ticket)
              var initials = ticket.name.split(" ").map(function (word) {
                return word[0].toUpperCase();
              });
              var initial_string = initials[0] + initials[1];
              var $block = $(".js-admin-search-tickets .items").append(
                controller.template({
                  url: ticket.url,
                  image: ticket.image,
                  name: ticket.id,
                  extra: ticket.extra,
                  initials: initial_string,
                })
              );
              if (ticket.image == null || ticket.image == undefined) {
                $block.find("img").remove();
                $block.find("div.hidden").removeClass("hidden");
              }
            }
          }

          var $results = $(".js-admin-search-items a");
          if ($results.length > 0) {
            $results.data("selectedItem", null);
          }

          $searchIconTarget.removeClass("hidden");
          $searchingIconTarget.addClass("hidden");
        });
      }, 300);
    } else if (value.length == 0) {
      $boxTarget.find(".js-admin-search-warning").addClass("hidden");
    } else {
      this.searchTimeout = setTimeout(function () {
        $boxTarget.find(".js-admin-search-warning").removeClass("hidden");
      }, 500);
    }

    return true;
  }

  selectCurrentItem() {
    var $results = $(".js-admin-search-items a");
    $results
      .removeClass("border-brand-500 bg-brand-50")
      .addClass("border-white");
    $($results[parseInt($results.data("currentItem"))])
      .removeClass("border-white")
      .addClass("border-brand-500 bg-brand-50")[0]
      .scrollIntoView({
        behavior: "smooth",
      });
  }
}
