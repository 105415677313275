/*

This controller implements a dynamic form for adding/removing
specific disclosures to a funding round.

Required structure:

<div data-controller="disclosures" data-funding-round-id="<%= @id %>">

  <div data-disclosures-target="body">
    Default warning about not having any disclosures
  </div>

  <div data-disclosures-target="add">
    <button data-action="click->disclosures#add">Add new disclosure</button>
  </div>

  <div>
    <button data-action="click->sections#save" type="submit">Save</button>
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class DisclosuresController extends Controller {
  static targets = ["body", "add"];

  connect() {
    var fundingRoundId = $(this.element).data("fundingRoundId")
    var stimulusThis = this

    $.get("/account/specific_disclosures", {
      funding_round_id: fundingRoundId
    }, function (data) {
      stimulusThis.loadData(data)
    })
  }

  loadData(data) {
    $(this.bodyTarget).html("")
    var stimulusThis = this

    $.each(data, function (key) {
      var disclosure = data[key]
      var template = $("#existing-disclosure-template").html()

      template = template.replace(/{{ID}}/g, disclosure.id)
      template = template.replace(/{{CLAUSE}}/g, disclosure.ssa_clause)
      template = template.replace(/{{CONTENT}}/g, "<p>" + disclosure.content.split("\n").join("</p><p>") + "</p>")

      $(stimulusThis.bodyTarget).append(template)
    })
  }

  cancel() {
    $(this.bodyTarget).find("form").remove()
    $(this.addTarget).removeClass("hidden")
  }

  add() {
    $(this.bodyTarget).find("form").remove()
    var template = $("#new-disclosure-template").html()
    $(this.bodyTarget).append(template)
    $(this.addTarget).addClass("hidden")
  }

  edit(e) {
    $(this.bodyTarget).find("form").remove()
    var template = $("#new-disclosure-template").html()
    var $template = $(this.bodyTarget).append(template)
    $(this.addTarget).addClass("hidden")

    var fundingRoundId = $(this.element).data("fundingRoundId")
    var disclosureId = $(e.target).closest("div").data("id")
    $.get("/account/specific_disclosures/" + disclosureId, {
      funding_round_id: fundingRoundId
    }, function (data) {
      $template.find("input[name=ssa_clause]").val(data.ssa_clause)
      $template.find("input[name=id]").val(data.id)
      $template.find("textarea[name=content]").val(data.content)
    })
  }

  save(e) {
    var $form = $(e.target).closest("form")
    var fundingRoundId = $(this.element).data("fundingRoundId")
    var disclosureId = $form.find("input[name=id]").val()
    var stimulusThis = this

    if (disclosureId != "" && disclosureId != undefined) {
      $.put("/account/specific_disclosures/" + disclosureId, {
        funding_round_id: fundingRoundId,
        ssa_clause: $form.find("input[name=ssa_clause]").val(),
        content: $form.find("textarea[name=content]").val()
      }, function (data) {
        stimulusThis.loadData(data)
        $(stimulusThis.addTarget).removeClass("hidden")
      })
    } else {
      $.post("/account/specific_disclosures/", {
        funding_round_id: fundingRoundId,
        ssa_clause: $form.find("input[name=ssa_clause]").val(),
        content: $form.find("textarea[name=content]").val()
      }, function (data) {
        stimulusThis.loadData(data)
        $(stimulusThis.addTarget).removeClass("hidden")
      })
    }
  }

  delete(e) {
    var stimulusThis = this

    var fundingRoundId = $(this.element).data("fundingRoundId")
    var disclosureId = $(e.target).closest("div").data("id")

    if (confirm($(e.target).closest("div").data("confirm"))) {
      $.delete("/account/specific_disclosures/" + disclosureId, {
        funding_round_id: fundingRoundId
      }, function (data) {
        stimulusThis.loadData(data)
        $(stimulusThis.addTarget).removeClass("hidden")
      })
    }
  }
}
