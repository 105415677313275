/*

CheckRedirect controller keeps calling the server via Ajax at the URL specified by it's
"data-url" attribute until the server responds with a JSON body where the "ok" member
is a redirect URL. When the server responds with a redirect URL, the browser is
redirected to that URL.
Required structure:

<div controller="check-redirect" data-url="/path/to/redirect"></div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class CheckRedirectController extends Controller {
  connect() {
    var url = $(this.element).data("url")
    var intervalId = setInterval(() => {
      $.get(url, function (data) {
        if (data.ok) {
          window.location = data.ok;
          clearInterval(intervalId);
        }
      });
    }, 1000);
  }
}
