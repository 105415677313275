/*

Expander controller

While this seems a more general version of ButtonExpanderController, in fact
it's used in a slightly different way because it allows both toggling of
whether the content is expanded, as well as expanding all. This is used on
the site for FAQs.

Required structure:

<div data-controller="expander">
  <div data-action="click->expander#toggle" data-expander-target="header">
    <h2>Title and chevron icon</h2>
  </div>

  <div data-expander-target="content">
    Body content
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class ExpanderController extends Controller {
  static targets = ["header", "content"];

  activeClassName() {
    var className = $(this.element).data("activeClassName")
    if (className === undefined || className === "") {
      "-rotate-180"
    }
    return className
  }

  connect() {
    if (this.headerTargets.length > 0) {
      $(this.headerTargets).find("svg").removeClass(this.activeClassName())
    }
    $(this.contentTargets).addClass("hidden")

    if ($(this.element).data("expanderAutoexpand") != undefined) {
      $(this.contentTargets).filter("[data-expander-autoexpand='" + $(this.element).data("expanderAutoexpand") + "']").removeClass("hidden")
    }
  }

  toggle(e) {
    var $target = $(e.target).closest("[data-action]")
    $target.siblings().toggleClass("hidden")
    if (this.headerTargets.length > 0) {
      $target.find("svg").toggleClass(this.activeClassName() + " hidden")
    }
  }

  expandAll(e) {
    if (this.headerTargets.length > 0) {
      $(this.headerTargets).find("svg").addClass(this.activeClassName())
    }
    $(this.contentTargets).removeClass("hidden")
  }

  contractAll(e) {
    if (this.headerTargets.length > 0) {
      $(this.headerTargets).find("svg").removeClass(this.activeClassName())
    }
    $(this.contentTargets).addClass("hidden")
  }
}
