import { Controller } from "@hotwired/stimulus";

export default class YesNoController extends Controller {
  static targets = ["content"];

  connect() {
    let radios = this.element.querySelectorAll('input[type=radio]');
    radios.forEach((radio) => radio.addEventListener('change', this.toggle.bind(this)))
  }

  toggle(e) {
    if (e.target.value == "true") {
      this.contentTargets.forEach((t) => t.classList.remove("hidden"))
    } else {
      this.contentTargets.forEach((t) => t.classList.add("hidden"))
    }
  }
}
