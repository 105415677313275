/*

This controller allows creating a bunch of clickable elements (such as tags)
and as they are clicked on or off, they update a hidden field with a
comma-separated list of the active elements.

Required structure:

<div data-controller="multi-select" data-active="bg-green-500" data-inactive="bg-gray-500">
  <%= f.hidden_field :tags, data: { multi_select_target: "field" } %>
  <div>
    <% TAGS.each do |t| %>
      <%= tag.span t, data: { action: "click->multi-select#toggle", multi_select_target: "button" } %>
    <% end %>
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class MultiSelectController extends Controller {
  static targets = ["field", "button"];

  connect() {
    var activeClass = $(this.element).data("active")
    var inactiveClass = $(this.element).data("inactive")

    var value = $(this.fieldTarget).val()
    var values = value.split(/\s*,\s*/)

    $(this.buttonTargets).each(function () {
      var $button = $(this)
      if (values.includes($button.text())) {
        $button.addClass(activeClass)
      } else {
        $button.addClass(inactiveClass)
      }
    })
  }

  toggle(e) {
    var $button = $(e.target)

    var activeClass = $(this.element).data("active")
    var inactiveClass = $(this.element).data("inactive")

    $button.toggleClass(activeClass).toggleClass(inactiveClass)

    this.update()
  }

  update() {
    var activeClass = $(this.element).data("active")
    var inactiveClass = $(this.element).data("inactive")
    var value = ""

    $(this.buttonTargets).each(function () {
      var $button = $(this)
      if ($button.hasClass(activeClass)) {
        value += $button.text() + ","
      }
    })

    value = value.slice(0, -1) // remove trailing comma

    $(this.fieldTarget).val(value)
  }
}
