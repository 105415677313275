/*

Specific controller to handle part of the new user form's role definition.

*/

import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class RoleFormController extends Controller {
  static targets = ["companyname", "level", "fundwarning"]

  connect() {
    this.changeLevel()
  }

  changeLevel() {
    if ($(this.levelTarget).val() == "investor-company" || $(this.levelTarget).val() == "fund") {
      $(this.companynameTargets).removeClass("hidden")
    } else {
      $(this.companynameTargets).addClass("hidden")
    }

    if ($(this.levelTarget).val() == "fund") {
      $(this.fundwarningTargets).removeClass("hidden")
    } else {
      $(this.fundwarningTargets).addClass("hidden")
    }
  }
}
