/*

Specifically used to implement definition sections in Funding Rounds and similar UIs.

*/

import {
  Controller
} from "@hotwired/stimulus";

import { template } from "lodash"

$ = window.$

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

export default class SectionsController extends Controller {
  static targets = ["icons", "header", "complete", "expansion", "form", "details"];

  connect() {
    if ($(document).data("activeSection") === undefined) {
      $(document).data("activeSection", this)
    } else {
      $(this.headerTarget).addClass("cursor-pointer")
    }

    $(this.formTarget).data("controller", this)

    let searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('section')) {
      console.log(`#funding-section${searchParams.get('section')} .section-header`)
      $(`#funding-section${searchParams.get('section')} .section-header`).trigger("click")
    }
  }

  show() {
    if ($(document).data("activeSection") === this) {
      return
    }

    if ($(document).data("activeSection") !== undefined) {
      var old = $(document).data("activeSection");
      $(old.formTarget).find("hr").removeClass("hidden");
      $(old.detailsTarget).removeClass("-mt-6");
      $(old.iconsTarget).removeClass("hidden");
      $(old.detailsTarget).addClass("hidden");
      $(old.headerTarget).addClass("cursor-pointer")
    }

    $(document).data("activeSection", this)

    $(this.formTarget).find("hr").addClass("hidden");
    $(this.detailsTarget).addClass("-mt-6");
    $(this.iconsTarget).addClass("hidden");
    $(this.detailsTarget).removeClass("hidden");
    $(this.headerTarget).removeClass("cursor-pointer")
    this.headerTarget.scrollIntoView({
      behavior: 'smooth'
    });
  }

  save(e) {
    e.preventDefault();

    var $formTarget = $(this.formTarget)
    var post_url = $formTarget.attr("action");
    var form_data = $formTarget.serialize();

    $formTarget.find(".js-input-container").each(function () {
      var $inputContainer = $(this)

      $inputContainer.find(".js-field-error").remove()
      $inputContainer.removeClass("border-l-4 border-red-400 pl-4")
      $inputContainer.find("input, select").addClass("border-gray-300 focus:border-yellow-700").removeClass("border-red-400 focus:border-rose-200")
    });

    $.ajax({
      url: post_url,
      type: "patch",
      data: form_data,
      success: (function (response) {
        if (response.redirect) {
          var templ = template($("#js-error-message-template").html());
          if (response.url) {
            window.location.href = response.url;
          } else {
            $(`#funding-section${response.section} .section-header`).trigger("click")
          }
        } else if (response.success) {
          $($(document).data("activeSection").expansionTarget).addClass("hidden")
          $($(document).data("activeSection").completeTarget).removeClass("hidden")

          var current = $("form.js-funding-round-form").index($(document).data("activeSection").formTarget)
          console.log(response)
          if ($("form.js-funding-round-form").length > current + 1) {
            $($("form.js-funding-round-form")[current + 1]).data("controller").headerTarget.click()
            if (response.warning != undefined && response.warning != "") {
              alert(response.warning)
            }
          } else {
            if (response.warning != undefined && response.warning != "") {
              alert(response.warning)
            }
            if (response.complete) {
              window.location.href = response.completeLocation;
            }
          }

          $(".js-error-messages").addClass("hidden");
        } else {
          $(this.expansionTarget).removeClass("hidden")
          $(this.completeTarget).addClass("hidden")

          var templ = template($("#js-error-message-template").html());

          var $scrollTarget = null;

          $.each(response.errors, function (field, errors) {
            var $input = $("input[name*=" + field + "], select[name*=" + field + "]")
            var $container = $input.closest(".js-input-container")
            var $label = $container.find("label")
            var $labelParent = $label.parent()

            $labelParent.append(templ({ field: $input.prop("name"), message: $label.html().replace("?", "") + " " + errors[0] }))
            if ($scrollTarget === null) {
              $scrollTarget = $container
            }

            $container.addClass("border-l-4 border-red-400 pl-4")
            $input.removeClass("border-gray-300 focus:border-yellow-700").addClass("border-red-400 focus:border-rose-200")
          });

          $scrollTarget[0].scrollIntoView({
            behavior: 'smooth'
          });

        }
      })
    });
  }
}
