/*

The form autosaves inputs to LocalStorage if data-autosave-target is provided
and clears the current autosave data on form submission.

Important: don't use a submit button to submit the form, use a button with the required
data-action.

Required structure:

<form ... data-controller="autosave">
  <div ...>
    <input type="text" name="input" data-autosave-target="input">
    <button data-action="click->autosave#submitForm">Save</button>
  </div>
</form>

*/

import {
  Controller
} from "@hotwired/stimulus";

export default class AutosaveController extends Controller {
  static targets = ["autosaveMessage", "autosaveSuccessMessage"];
  connect() {
    const formElement = this.element.querySelector('form');
    const submitUrl = formElement.getAttribute('action');
    var formElements = this.element.querySelectorAll('input[data-autosave-target], textarea[data-autosave-target]');
    if (formElements.length == 0) {
      formElements = this.element.querySelectorAll('input, textarea');
    }
    formElements.forEach(element => {
      const target = element.id;
      const storedJsonString = localStorage.getItem(submitUrl);
      const storedObject = JSON.parse(storedJsonString);
      if (storedObject && storedObject[target]) {
        element.value = storedObject[target];
        element.dispatchEvent(new Event('change'));
        this.autosaveMessageTarget.classList.remove("hidden");
      }
      element.addEventListener("input", () => {
        clearTimeout(this.autoSaveTimeout);
        this.autoSaveTimeout = setTimeout(() => {
          this.autoSave(submitUrl);
          element.dispatchEvent(new Event('change'));
        }, 2000);
      });
    });
  }

  disconnect() {
    clearTimeout(this.autoSaveTimeout);
  }

  autoSave(url) {
    const formData = this.getAutoSaveFormData();
    if (!url) return;

    const content = JSON.stringify(formData);
    localStorage.setItem(url, content);
    this.showSuccessMessage("Content autosaved");
  }

  getAutoSaveFormData() {
    const formData = {};
    var formElements = this.element.querySelectorAll('input[data-autosave-target], textarea[data-autosave-target]');
    if (formElements.length == 0) {
      formElements = this.element.querySelectorAll('input, textarea');
    }
    formElements.forEach(element => {
      const key = element.id;
      const value = element.value;
      formData[key] = value;
    });
    return formData;
  }

  showSuccessMessage(message) {
    const successMessage = this.autosaveSuccessMessageTarget
    successMessage.textContent = message;
    successMessage.classList.remove("opacity-0");
    successMessage.classList.add("opacity-100");
    setTimeout(() => {
      successMessage.classList.add("transition-opacity", "duration-1000");
      successMessage.classList.remove("opacity-100");
      successMessage.classList.add("opacity-0");
    }, 2000);
  }

  submitForm(event) {
    const formElement = this.element.querySelector('form');
    const submitUrl = formElement.getAttribute('action');
    localStorage.removeItem(submitUrl)
  }
}
