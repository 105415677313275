import {
  Controller
} from "@hotwired/stimulus";

// <div data-controller="show-pdf">
//   <button data-show-pdf-target="showpdf" data-action="click->show-pdf#openPdf" data-url="/url">Open PDF</button>
// <div>
export default class ShowPdfController extends Controller {
  static targets = ["content", "showpdf"];

  connect(){
    this.togglePdfButton();
  }

  openPdf(event) {
    event.preventDefault();
    const contentValue = this.contentTarget.value;
    const baseUrl = this.showpdfTarget.dataset.url;
    const url = `${baseUrl}?content=${encodeURIComponent(contentValue)}`;
    fetch(url, {
      method: 'GET'
    }).then(response => {
      if (response.ok) {
        return response.blob();
      }
    }).then(blob => {
      let pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");
    })
  }

  togglePdfButton() {
    const content = this.contentTarget.value.trim();
    if (content !== '') {
      this.showpdfTarget.classList.remove('hidden');
    } else {
      this.showpdfTarget.classList.add('hidden');
    }
  }
}