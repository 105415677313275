import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchResults", "commentField", "spinner"]

  connect() {
    this.timeout = null;
  }

  searchArticle(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const query = event.target.value.trim().toLowerCase();
      if (query.length > 2) {
        this.performSearch(query);
      } else {
        this.searchResultsTarget.innerHTML = "";
        this.hideSpinner();
      }
    }, 300);
  }

  performSearch(query) {
    const url = `search_knowledge_base?query=${encodeURIComponent(query)}`;
    this.showSpinner();
    fetch(url)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.displayResults(data.knowledge_base_articles);
        this.hideSpinner();
      })
      .catch(error => {
        this.hideSpinner();
      });
  }

  displayResults(results) {
    this.searchResultsTarget.innerHTML = "";

    if (results.length === 0) {
      const emptyResults = document.createElement("p");
      emptyResults.textContent = "No results found.";
      emptyResults.classList.add("text-gray-500", "italic", "p-2", "absolute", "w-full", "bg-white", "border", "border-gray-200", "rounded-lg", "shadow-lg", "z-50");
      this.searchResultsTarget.appendChild(emptyResults);
  
      setTimeout(() => {
        emptyResults.remove();
      }, 1000); 
  
      return;
    }

    const ul = document.createElement("ul");
    ul.classList.add("search-results-list", "absolute", "w-full", "bg-white", "border", "border-gray-200", "rounded-lg", "shadow-lg", "z-50", "max-h-60", "overflow-y-auto");

    results.forEach(article => {
      const li = document.createElement("li");
      li.classList.add("search-result-item", "flex", "justify-between", "items-center", "p-2", "hover:bg-gray-100");

      const titleSpan = document.createElement("span");
      titleSpan.textContent = article.title;

      const addButton = document.createElement("button");
      addButton.classList.add("ml-2", "px-2", "py-1", "text-sm", "text-white", "bg-brand-600", "hover:bg-brand-500", "rounded");
      addButton.textContent = "Add";
      addButton.addEventListener("click", (event) => {
        event.preventDefault();
        this.insertLink(article);
      });

      li.appendChild(titleSpan);
      li.appendChild(addButton);
      ul.appendChild(li);
    });

    this.searchResultsTarget.appendChild(ul);
  }

  insertLink(article) {
    const linkText = `Knowledgebase: ${article.title}`;
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const url = `${baseUrl}/knowledge_base/${article.slug}`;
    const markdownLink = `[${linkText}](${url})`;
    
    const commentField = this.commentFieldTarget;
    const startPos = commentField.selectionStart;
    const endPos = commentField.selectionEnd;
  
    commentField.value = commentField.value.substring(0, startPos) + markdownLink + commentField.value.substring(endPos, commentField.value.length);
    
    commentField.focus();
    commentField.selectionStart = startPos + markdownLink.length;
    commentField.selectionEnd = startPos + markdownLink.length;
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }
  
}