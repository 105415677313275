/*

A very specific controller to calculate the pricing for comparison
on the SeedLegals page.

Required structure:

<div data-controller="pricing" data-pricing-our-initial-price="10000">
  <input type="range" data-pricing-target="slider" min="0" max="409" value="0">
  <div data-pricing-target="raise"></div>
  <div data-pricing-target="competitionprice"></div>
  <div data-pricing-target="ourprice"></div>
  <div data-pricing-target="lawfirmprice"></div>
  <input type="checkbox" data-pricing-target="aa">include AA?
  <input type="checkbox" data-pricing-target="compliance"> include compliance?
  <input type="checkbox" data-pricing-target="agile"> include Agile?
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

// 5,000 to 250,000 in 1000 increments = 245 steps
// 250,000 to 1,000,000 in 10,000 increments = 75 steps
// 1,000,000 to 10,000,000 in 100,000 increments = 90 steps
// Total steps = 410

export default class PricingController extends Controller {
  static targets = ["slider", "raise", "competitionprice", "ourprice", "lawfirmprice", "aa", "compliance", "agile"];

  connect() {
    this.calculate()
  }

  calculate(event) {
    let step = parseInt(this.sliderTarget.value)
    let raise = 0

    if (step < 245) {
      raise = (step * 1000) + 5000
    } else {
      step -= 245
      raise = (245 * 1000) + 5000

      if (step < 75) {
        raise += (step * 10000)
      } else {
        step -= 75
        raise += (75 * 10000)

        if (step > 0) {
          raise += (step * 100000)
        }
      }
    }

    this.raiseTarget.innerHTML = raise.toLocaleString()

    let competitorPrice = this.calculateCompetitorInitialPrice(raise)
    this.competitionpriceTarget.innerHTML = competitorPrice.toLocaleString()

    let ourPrice = parseInt(this.element.dataset.ourInitialPrice)
    if (this.agileTarget.checked) {
      ourPrice += parseInt(this.element.dataset.ourAgilePrice)
    }
    this.ourpriceTarget.innerHTML = ourPrice.toLocaleString()

    if (raise <= 300000) {
      this.lawfirmpriceTarget.innerHTML = "7,500"
    } else if (raise <= 600000) {
      this.lawfirmpriceTarget.innerHTML = "10,000"
    } else if (raise <= 2000000) {
      this.lawfirmpriceTarget.innerHTML = "15,000"
    } else {
      this.lawfirmpriceTarget.innerHTML = "25,000"
    }
  }

  calculateCompetitorInitialPrice(raise) {
    let price = 0

    if (raise <= 500000) {
      price = raise * 0.01
    } else if (raise <= 1000000) {
      price = 500000 * 0.01 + (raise - 500000) * 0.005
    } else if (raise <= 2000000) {
      price = 500000 * 0.01 + 500000 * 0.005 + (raise - 1000000) * 0.002
    } else if (raise <= 10000000) {
      price = 500000 * 0.01 + 500000 * 0.005 + 1000000 * 0.002 + (raise - 2000000) * 0.001
    } else {
      price = 500000 * 0.01 + 500000 * 0.005 + 1000000 * 0.002 + 8000000 * 0.001 + (raise - 10000000) * 0.0005
    }

    if (price < 1990) {
      price = 1990
    }

    if (this.aaTarget.checked) {
      price += 390
    }

    if (this.complianceTarget.checked) {
      price += 490
    }

    if (this.agileTarget.checked) {
      price += this.calculateCompetitonAgilePrice(raise)
    }

    return price
  }

  calculateCompetitonAgilePrice(raise) {
    let price = 0

    if (raise <= 500000) {
      price = raise * 0.01
    } else if (raise <= 1000000) {
      price = 500000 * 0.01 + (raise - 500000) * 0.005
    } else if (raise <= 2000000) {
      price = 500000 * 0.01 + 500000 * 0.005 + (raise - 1000000) * 0.002
    } else if (raise <= 10000000) {
      price = 500000 * 0.01 + 500000 * 0.005 + 1000000 * 0.002 + (raise - 2000000) * 0.001
    } else {
      price = 500000 * 0.01 + 500000 * 0.005 + 1000000 * 0.002 + 8000000 * 0.001 + (raise - 10000000) * 0.0005
    }

    if (price < 100) {
      price = 100
    }

    return price
  }
}
