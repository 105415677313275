import { Controller } from "@hotwired/stimulus";

export default class NumberTextFieldEntryController extends Controller {
  connect() {
    this.inputElement = this.element.querySelector('input');
    this.integerOnly = this.inputElement.getAttribute('integer_only') === 'true';
    if (this.inputElement) {
      this.inputElement.addEventListener('input', this.validateNumber.bind(this));
      this.inputElement.addEventListener('paste', this.validateNumber.bind(this));
    }
  }

  validateNumber(event) {
    const inputValue = this.inputElement.value;

    let sanitizedValue = this.integerOnly
      ? inputValue.replace(/[^0-9]/g, '')
      : inputValue.replace(/[^0-9.]/g, '');

    if (!this.integerOnly) {
      const dotOccurrences = (sanitizedValue.match(/\./g) || []).length;
      if (dotOccurrences > 1) {
        sanitizedValue = sanitizedValue.substring(0, sanitizedValue.lastIndexOf('.'));
      }
    }

    this.inputElement.value = sanitizedValue;

    if (this.inputElement.value !== inputValue) {
      event.preventDefault();
    }
  }
}
