import {
  Controller
} from "@hotwired/stimulus";

export default class TextItemsListController extends Controller {
  add(event) {
    console.log(event)
    let input = document.createElement("input")
    input.setAttribute("type", "text")
    input.setAttribute("name", event.target.dataset.method + "[]")
    input.classList.add("form-input", "text-base", "py-3", "px-4", "rounded-lg", "bg-gray-50", "border", "border-gray-200", "font-light", "w-full")
    this.element.insertBefore(input, event.target.parentNode)
    event.preventDefault()
  }
}
