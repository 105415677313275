/*

A simple overlay component, that can be triggered by a click event.
Clicking outside of the overlay will close it. This is in contrast
to a Modal which requires an explicit close.

Required structure:

<div data-controller="popup">
  <div data-action="click->popup#show" data-popup-target="content" class="hidden">
    Overlay content
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class PopupController extends Controller {
  static targets = ["content"];

  connect() {
    $(document.body).on("click", this.removeOverlay.bind(this))
  }

  show(e) {
    if ($(e.target).is("a")) {
      return
    }
    $(this.contentTargets).removeClass("hidden")
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  removeOverlay(e) {
    $(this.contentTargets).addClass("hidden")
  }
}
