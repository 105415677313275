/*

This controller handles a button click, then an Ajax request upon
an interval to check the status of a download, and then updates the
button's appearance based on the status.

Required structure:

<div data-controller="download-dataroom" data-download-state="missing" data-download-url="/download-dataroom">
  <svg class="js-download-dataroom-prepare hidden" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" />
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class DownloadDataroomController extends Controller {
  static targets = ["title", "content"];

  connect() {
    $(this.element).addClass("bg-brand-700 p-6 rounded-lg shadow-xl text-white flex items-center space-x-10")
    this.transitionStatus()
  }

  transitionStatus() {
    var $this = $(this.element)
    var state = $this.data("download-state")

    $this.find("svg").addClass("hidden")
    if (state == "missing") {
      $this.find(".js-download-dataroom-prepare").removeClass("hidden")
      $this.addClass("cursor-pointer hover:bg-brand-600").removeClass("cursor-wait").find(".js-download-dataroom-label").html('<div class="font-bold text-xl">Prepare Intelligent Data Room download</div><div>(In ZIP archive format)</div>')
    } else if (state == "ready") {
      $this.find(".js-download-dataroom-download").removeClass("hidden")
      $this.addClass("cursor-pointer hover:bg-brand-600").removeClass("cursor-wait").find(".js-download-dataroom-label").html('<div class="font-bold text-xl">Download Intelligent Data Room</div><div>(In ZIP archive format)</div>')
    } else {
      $this.find(".js-download-dataroom-preparing").removeClass("hidden")
      $this.removeClass("cursor-pointer hover:bg-brand-600").addClass("cursor-wait").find(".js-download-dataroom-label").html('<div class="font-bold text-xl">Creating Intelligent Data Room download</div><div>(In ZIP archive format)</div>')
    }
  }

  click(e) {
    var $this = $(this.element)
    if ($this.hasClass("cursor-wait")) {
      return;
    }

    var state = $this.data("download-state");
    if (state == "missing") {
      this.checkStatus()
    } else if (state == "ready") {
      location.href = $this.data("download-url")
    }
  }

  checkStatus(e) {
    var $this = $(this.element)
    var _this = this
    $.get($this.data("download-url"), function (data, status, jqXHR) {
      $this.data("download-state", data.status)
      _this.transitionStatus()
      if (data.status != "ready") {
        setTimeout(function () { _this.checkStatus() }, 2000)
      }
    })
  }
}
