/*

HOW TO USE:

The top level class should have the data-controller="clipboard" attribute.

It can then either specify the "data-clipboard" attribute for the content to copy
or it can have a subelement with the data-clipboard-target="content" attribute.

Then you just need to assign the action to copy it

<div data-controller="clipboard"
 data-clipboard="https://www.google.com"
 data-action="click->clipboard#copy">
  https://www.google.com
</div>

or

<div data-controller="clipboard" data-action="click->clipboard#copy">
  <div data-clipboard-target="content">https://www.google.com</div>
</div>

The rest of the attributes are optional and purely for display purposes.

The top level element can have a "data-success-class" and "data-reset-class" used
for applying one or more CSS classes to the "content" target when copying and then
resetting it back again after the copy.

<div data-controller="clipboard" data-clipboard="https://www.google.com"
 data-success-class="text-green-600" data-reset-class="text-gray-700"
 data-action="click->clipboard#copy">
  <div data-clipboard-target="content">https://www.google.com</div>
</div>

Also you can have subelements with a "data-clipboard-target" of "alpha" and
"omega" for displaying the copy and checked icons respectively. These are
shown and hidden by adding/removing the "hidden" class. The Alpha is presumed
to be visible at the start and the Omega is hidden at the start.

<div data-controller="clipboard" data-clipboard="https://www.google.com"
 data-action="click->clipboard#copy">
  <div>https://www.google.com</div>
  <div data-clipboard-target="alpha"><%= svg_img "icons/copy" %></div>
  <div class="hidden" data-clipboard-target="omega"><%= svg_img "icons/check-regular" %></div>
</div>

*/

import { Controller } from "@hotwired/stimulus";

$ = window.$

export default class ClipboardController extends Controller {
  static targets = [ "alpha", "content", "omega" ]

  copy(e) {
    var $elem = $(this.element)

    if ($elem.data("timeoutID") != undefined) {
      clearTimeout($elem.data("timeoutID"))
    }

    var contents = $elem.data("clipboard")
    if (contents == undefined) {
      contents = this.contentTarget.innerText
    }
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(contents);
    }

    var $alphaTarget = $(this.alphaTarget)
    var $contentTarget = $(this.contentTarget)
    var $omegaTarget = $(this.omegaTarget)
    var successClass = $elem.data("success-class")
    var resetClass = $elem.data("reset-class")

    $alphaTarget.addClass("hidden")
    $contentTarget.addClass(successClass).removeClass(resetClass)
    $omegaTarget.removeClass("hidden")
    var timeoutID = setTimeout(function() {
      $alphaTarget.removeClass("hidden")
      $contentTarget.removeClass(successClass).addClass(resetClass)
      $omegaTarget.addClass("hidden")
      $elem.data("timeoutID", undefined)
    }, 2000)
    $elem.data("timeoutID", timeoutID)
  }
}
