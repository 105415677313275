/*

A simple controller to embed a PDF viewer onto the page.

Required structure:

<div data-controller="pdf-viewer">
  <div data-pdf-viewer-target="embed" data-pdf-viewer-src="/foo.pdf"></div>
    <div data-pdf-viewer-target="fallback">
      Unable to embed - <a href="/foo.pdf">Download PDF</a>
    </div>
  </div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class PoolPercentageController extends Controller {
  connect() {
    var $container = $(this.element).closest("div")
    $container.addClass("flex space-x-2 items-center")
    var $label = $('<div class="text-gray-700 whitespace-nowrap flex-shrink-0"></div>')
    $container.append($label)
    $(this.element).data("label", $label)
    this.update(null)
  }

  update(e) {
    var percentage = (parseInt(this.element.value) || 0) / $(this.element).data("totalShares") * 100
    $(this.element).data("label").text(percentage.toFixed(2) + "% of your cap table");
  }
}
