import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class SharesCalculatorController extends Controller {
  static targets = ["shares", "price", "result", 'minprice']

  connect() {
    this.update(undefined)
  }

  update(e) {
    var shares = parseInt($(this.sharesTarget).val())
    var price = parseFloat($(this.priceTarget).val())
    if (shares && price) {
      var total = shares * price

      $(this.resultTarget).text("£" + total.toLocaleString('en-US', {maximumFractionDigits:2}))
    } else {
      $(this.resultTarget).text("£0")
    }
  }

  reset(e) {
    e.preventDefault();
    var minprice = $(this.minpriceTarget).val()
    $(this.priceTarget).val(minprice);
  }
}
