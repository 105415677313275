/*

General controller to implement a "select all" checkbox at the top
of a list of checkboxes, e.g. signatures required page.

Required structure:

<div data-controller="select-all">
  <input type="checkbox" data-action="change->select-all#change" data-select-all-target="all">All</input>
  <input type="checkbox" data-action="change->select-all#customise" data-select-all-target="checkbox">1</input>
  <input type="checkbox" data-action="change->select-all#customise" data-select-all-target="checkbox">2</input>
  <input type="submit" data-select-all-target="button" disabled>Submit</input>
</div>

*/

import { Controller } from "@hotwired/stimulus"

$ = window.$

export default class SelectAllController extends Controller {
  static targets = ["all", "checkbox", "button", "checked", "unchecked"]

  connect() {
    this.setSubmitState()
  }

  change(e) {
    var $element = $(e.target);
    $(this.checkboxTargets).prop('checked', $element.is(":checked"));
    this.setSubmitState()
  }

  customise(e) {
    $(this.allTarget).prop("checked", $(this.checkboxTargets).length == $(this.checkboxTargets).filter(":checked").length)
    this.setSubmitState()
  }

  setSubmitState() {
    if ($(this.checkboxTargets).filter(":checked").length > 0) {
      $(this.buttonTarget).prop("disabled", false)
    } else {
      $(this.buttonTarget).prop("disabled", true)
    }

    if ($(this.checkboxTargets).filter(":checked").length > 0) {
      $(this.checkedTarget).removeClass("hidden")
      $(this.uncheckedTarget).addClass("hidden")
    } else {
      $(this.checkedTarget).addClass("hidden")
      $(this.uncheckedTarget).removeClass("hidden")
    }
  }
}
