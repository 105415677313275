/*

Advert controller

CURRENTLY DISABLED. This is used when you render the common/_top_advert_bar partial
to inject a view if the user hasn't already seen it (and if they have and choose
to close it, it will set a cookie) for advertising a service or product.

If you change the service/product, set the data-id on the partial to change the
value this controller looks for in the cookie.

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class AdvertsController extends Controller {
  connect() {
    var $elem = $(this.element)
    if (this.getCookie("advert_seen") != $elem.data("id") && $elem.data("id") !== "" && $elem.data("id") !== undefined) {
      $elem.removeClass("hidden")
    }
  }

  close() {
    var $elem = $(this.element)
    this.setCookie("advert_seen", $elem.data("id"), 28)
    $elem.remove()
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
