/*

Used in conjunction with the DarkController to implement a three way toggleable radio button.

Required structure:

<div data-controller="radio">
  <input type="hidden" data-radio-target="input">
  <div data-action="click->dark#light">Light</div>
  <div data-action="click->dark#auto">Auto</div>
  <div data-action="click->dark#dark">Dark</div>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class RadioController extends Controller {
  static targets = ["input", "button"];

  connect() {
    var $elem = $($(this.buttonTargets).first())
    this.radioSelect($elem)
  }

  click(e) {
    var $elem = $(e.target).closest(".js-radio-click")
    this.radioSelect($elem)
  }

  radioSelect($elem) {
    this.deselectAll()

    var key = $elem.data("key")

    $(this.inputTarget).prop("value", key)

    $($elem).addClass("border-green-500").removeClass("border-gray-200")
      .find(".js-radio-number").addClass("bg-green-200").removeClass("bg-gray-200")

    var check = $("#radio-check").html()
    $elem.prepend(check)
  }

  deselectAll() {
    var $buttons = $(this.buttonTargets)
    $buttons.removeClass("border-green-500").addClass("border-gray-200")
    $buttons.find(".js-radio-number").removeClass("bg-green-200 text-green-800").addClass("bg-gray-200 text-gray-800")
    $buttons.find(".js-radio-check").remove()
  }
}
