/*

This controller is used to mark one or more investments as received
by the company during a funding round.

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class InvestmentCompletionController extends Controller {
  static targets = ["investor", "form"];

  toggle(e) {
    var $button = $(e.target)
    $button.toggleClass("bg-brand-500 text-white text-brand-500 border border-brand-500 hover:bg-brand-600")

    if ($button.is(".bg-brand-500")) {
      $button.html("Mark as received")
    } else {
      $button.html("Confirm below")
    }

    this.updateForm()
  }

  updateForm() {
    var $checked = $(this.investorTargets).filter(".text-brand-500")

    if ($checked.length > 0) {
      $(this.formTarget).removeClass('hidden')
    } else {
      $(this.formTarget).addClass('hidden')
    }

    var ids = $checked.map(function () {
      return $(this).data("id");
    }).get().join(',');
    document.getElementById("shareholding_ids").value = ids
  }
}
