import hotkeys from 'hotkeys-js';
import $ from 'jquery'
window.jQuery = $
window.$ = $

hotkeys('cmd+k,ctrl+k,esc', function (event, handler){
  switch (handler.key) {
    case 'cmd+k':
      $(".js-admin-search-trigger").trigger("click");
      break;
    case 'ctrl+k':
      $(".js-admin-search-trigger").trigger("click");
      break;
    case 'esc':
      $(".js-admin-search-close").trigger("click");
      break;
  }
});
