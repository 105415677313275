/*

This controller implements the dropdown menu for the avatar part of the public header.

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class PublicNavController extends Controller {
  static targets = ["header", "items"];

  toggle() {
    $(this.itemsTarget).toggleClass("hidden")
    if ($(this.itemsTarget).hasClass("hidden")) {
      $(this.headerTarget).addClass("md:fixed")
    } else {
      $(this.headerTarget).removeClass("md:fixed")
    }
  }

  toggleAccountMenu() {
    $(this.accountMenuTarget).toggleClass("lg:hidden")
  }
}
