import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class TailwindRadioButtonGroupController extends Controller {
  static targets = ["field", "option"];

  connect() {
    // TODO: This following line feels wrong, feels like it should be hooked up with Stimulus, but then "this" is incorrect
    $(this.optionTargets).on("click", this.click.bind(this))

    var $container = $(this.element)
    this.activeClasses = $container.data("active-classes")
    this.inactiveClasses = $container.data("inactive-classes")
    this.currentValue = $container.data("current-value").toString()

    $container.find(".js-tailwind-radio-button-group-option").addClass(this.inactiveClasses)
    this.click({ target: $container.find(".js-tailwind-radio-button-group-option[data-value='" + this.currentValue + "']")[0] })
  }

  click(e) {
    var $item = $(e.target).closest(".js-tailwind-radio-button-group-option")
    var key = $item.data("value")
    $(this.fieldTarget).val(key)
    $(this.element).data("current-value", key)

    $(this.optionTargets).removeClass(this.activeClasses).addClass(this.inactiveClasses)
    $item.addClass(this.activeClasses).removeClass(this.inactiveClasses)

    $(this.optionTargets).find(".js-tailwind-radio-button-group-option-icon").each(function(index, elem) {
      var $elem = $(elem)
      $elem.addClass($elem.data("inactive-classes")).removeClass($elem.data("active-classes"))
    })
    $item.find(".js-tailwind-radio-button-group-option-icon").each(function(index, elem) {
      var $elem = $(elem)
      $elem.addClass($elem.data("active-classes")).removeClass($elem.data("inactive-classes"))
    })
  }
}
