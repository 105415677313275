import { Controller } from "@hotwired/stimulus"

$ = window.$

import SignaturePad from "signature_pad"
import trimCanvas from 'trim-canvas'

export default class SignatureController extends Controller {
  static targets = ["canvas", "hidden"]

  connect() {
    var sp = new SignaturePad(this.canvasTarget);
    $(this.canvasTarget).data('signaturePad', sp);
  }

  save() {
    trimCanvas(this.canvasTarget)
    $(this.hiddenTarget).val($(this.canvasTarget).data("signaturePad").toDataURL())
  }

  clear(e) {
    $(this.canvasTarget).data("signaturePad").clear()
    e.preventDefault()
    return false
  }
}
