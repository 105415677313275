import { Controller } from "@hotwired/stimulus";

// <form action="/upload" method="post" data-controller="upload-files">
//   <div ...>
//     <span>Drop here...</span>
//   </div>
// </form>

import Dropzone from 'dropzone'

export default class UploadFilesController extends Controller {
  static targets = ["drop"];

  connect() {
    var controller = this
    var $form = $(this.element);
    var $target = $(this.dropTargets[0])

    var AUTH_TOKEN = $('meta[name="csrf-token"]').attr("content");

    console.log("In connect")

    var myDropzone = new Dropzone($target[0], {
      uploadMultiple: true,
      disablePreview: false,
      previewsContainer: false,
      autoProcessQueue: true,
      clickable: this.dropTargets[0],
      params: {
        authenticity_token: AUTH_TOKEN,
      },
      url: $form.attr("action"),
      allowDrop: function (event) {
        return (
          dataTransfer.items.length > 0 &&
          dataTransfer.items[0].kind === "file"
        );
      },
    });
    console.log("Dropzone created", myDropzone)

    myDropzone.on("drop", function () {
      $form.find(".js-drop-label").html("Uploading files, please wait...")
    });

    myDropzone.on("success", function (file, response) {
      if (response.location != undefined) {
        window.location.href = response.location
      }
    });
  }
}
